<template>
  <div>
    <b-row>
      <b-col cols="12">
        <template v-for="(report, report_index) in p_analyzeReports">
          <template v-if="report && report.data && report.data.general">
            <template v-if="report.data.general.report_type && report.data.general.report_type.val">
              <template v-if="report.data.general.report_type.val.value === 'total_count'">
                <template v-if="report.data.general.report_name_selectable && report.data.general.report_name_selectable.val && report.data.general.report_name_selectable.val.value">
                  <b-row style="margin: 0px;">
                    <b-col sm="12" lg="12" style="padding: 1px;">
                      <b-row style="margin: 0px;">
                        <b-col cols="8" style="padding: 1px;">
                          <strong style="color: #7e0303;"> {{ report.label }} </strong>
                        </b-col>
                        <b-col cols="4" style="padding: 1px; font-size: 10px;">
                          <strong>{{ report.data.general.total_count.val }}</strong>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
                <template v-else>
                  <b-row style="margin: 0px;">
                    <b-col sm="12" lg="12" style="padding: 1px;">
                      <b-card no-body bg-variant="whitey">
                        <b-row style="margin: 0px;">
                          <b-col cols="8" style="padding: 1px;">
                            <strong style="color: #7e0303;">
                                <template v-if="report.data.general.report_name_manuel && report.data.general.report_name_manuel.val">
                                  {{ report.data.general.report_name_manuel.val }}
                                </template>
                              </strong>
                          </b-col>
                          <b-col cols="4" style="padding: 1px; font-size: 10px;">
                            <strong>{{ report.data.general.total_count.val }}</strong>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </template>
          </template>
        </template>
        <template v-for="(report, report_index) in p_analyzeReports">
          <template v-if="report && report.data && report.data.general">
            <template v-if="report.data.general.report_type && report.data.general.report_type.val">
              <template v-if="report.data.general.report_type.val.value === 'percent'">
                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="12" style="padding: 1px;">
                    <b-card no-body bg-variant="whitey">
                      <b-row style="margin: 0px;">
                        <b-col cols="8" style="padding: 1px;">
                          <strong style="color: #7e0303;">
                              <template v-if="report.data.general.report_name_selectable && report.data.general.report_name_selectable.val && report.data.general.report_name_selectable.val.value">
                                {{ report.label }}
                              </template>
                              <template v-if="report.data.general.report_name_manuel && report.data.general.report_name_manuel.val">
                                {{ report.data.general.report_name_manuel.val }}
                              </template>
                            </strong>
                        </b-col>
                        <b-col cols="4" style="padding: 1px; font-size: 10px;">
                          <strong>{{ report.data.general.percent.val }}</strong>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
            </template>
          </template>
        </template>
        <template v-for="(report, report_index) in p_analyzeReports">
          <template v-if="report && report.data && report.data.general">
            <template v-if="report.data.general.report_type && report.data.general.report_type.val">
              <template v-if="report.data.general.report_type.val.value === 'chart'">
                <b-row :key="'chart_' + report_index" v-if="report.data.general.chart_type && report.data.general.chart_type.val && report.data.general.chart_type.val.value" style="margin: 0px;">
                  <b-col sm="12" lg="12" style="padding: 1px;">
                    <b-card no-body>
                      <b-card-header class="p-1" header-bg-variant="white">
                        <b-row style="margin: 0px;">
                          <b-col cols="9" style="font-size: 10px;">
                            <strong style="color: #7e0303;">
                                <template v-if="report.data.general.report_name_selectable && report.data.general.report_name_selectable.val && report.data.general.report_name_selectable.val.value">
                                  {{ report.label }}
                                </template>
                                <template v-if="report.data.general.report_name_manuel && report.data.general.report_name_manuel.val">
                                  {{ report.data.general.report_name_manuel.val }}
                                </template>
                              </strong>
                          </b-col>
                          <b-col cols="3" style="text-align: right;">
                            <!-- 
                              <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="sola ilerle" size="md" @click="f_moveChartData(report_index, 'left')">
                                <img src="@/icon/1277677.png" style="width: 2em;" />
                              </b-button>
                              <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="sağa ilerle" size="md" @click="f_moveChartData(report_index, 'right')">
                                <img src="@/icon/1277676.png" style="width: 2em;" />
                              </b-button>
                              <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="tümünü göster" size="md" @click="f_moveChartData(report_index, 'all')">
                                <img src="@/icon/453632.png" style="width: 2em;" />
                              </b-button>
                              <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" title="tümünü göster" size="md" @click="f_moveChartData(report_index, 'reset')">
                                <img src="@/icon/937437.png" style="width: 2em;" />
                              </b-button>
                             --></b-col>
                        </b-row>
                      </b-card-header>
                      <template v-if="report.data.general.chart_type.val.value === 'bar'">
                        <div :style="f_calculateChartStyle(report)">
                          <bar-view v-if="report.show" :height="200" :width="f_calculateChartWidth()" :chartId="'chart_' + report_index.toString()" :chart_data="f_getChartData(report)" />
                        </div>
                      </template>
                      <template v-if="report.data.general.chart_type.val.value === 'doughnut'">
                        <div :style="f_calculateChartStyle(report)">
                          <doughnut v-if="report.show" -view :height="200" :width="200" :chartId="'chart_' + report_index.toString()" :chart_data="f_getChartData(report)" />
                        </div>
                      </template>
                      <template v-if="report.data.general.chart_type.val.value === 'line'">
                        <div :style="f_calculateChartStyle(report)">
                          <line-view v-if="report.show" :height="200" :width="f_calculateChartWidth()" :chartId="'chart_' + report_index.toString()" :chart_data="f_getChartData(report)" />
                        </div>
                      </template>
                      <template v-if="report.data.general.chart_type.val.value === 'pie'">
                        <div :style="f_calculateChartStyle(report)">
                          <bar-view v-if="report.show" :height="200" :width="200" :chartId="'chart_' + report_index.toString()" :chart_data="f_getChartData(report)" />
                        </div>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
            </template>
          </template>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PieView from '@/components/chart/PieView';
import LineView from '@/components/chart/LineView';
import ScatterView from '@/components/chart/ScatterView';
import BarView from '@/components/chart/BarView';
import DoughnutView from '@/components/chart/DoughnutView';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'AnalyzeReportView',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_analyzeReports: {
      type: Array,
      required: true
    }
  },
  components: {
    LineView,
    ScatterView,
    BarView,
    DoughnutView,
    PieView
  },
  data () {
    return {
      d_timerControlProjectUpdate: { 'timer': 0 },
      d_totalCountPriorityReportOrder: ['totalcount_total_marketing_revenue', 'totalcount_total_primary_marketing_revenue', 'totalcount_total_secondary_marketing_revenue', 'totalcount_protocolopen', 'totalcount_primary_protocolopen', 'totalcount_secondary_protocolopen', 'totalcount_appointment', 'totalcount_primary_appointment', 'totalcount_secondary_appointment'],
      d_reportType: {
        'selected': '',
        'list': [{
          "value": "chart",
          "label": "grafik"
        }, {
          "value": "total_count",
          "label": "toplam adet"
        }, {
          "value": "percent",
          "label": "yüzde"
        }, {
          "value": "",
          "label": "hepsi"
        }]
      },
      d_reportNameType: {
        'selected': '',
        'list': [{
          "value": "selectable",
          "label": "seçilebilir"
        }, {
          "value": "manuel",
          "label": "manuel"
        }, {
          "value": "",
          "label": "hepsi"
        }]
      },
    };
  },
  created: function () {
    for (let report_index in this.p_analyzeReports) {
      this.p_analyzeReports[report_index].start = 0;
      this.p_analyzeReports[report_index].show = true;
      this.p_analyzeReports[report_index].end = 1000000;
      this.p_analyzeReports[report_index].max_show_point = 50;
      try {
        this.p_analyzeReports[report_index].start = this.p_analyzeReports[report_index].data.general.chart_data_label.list.length - this.p_analyzeReports[report_index].max_show_point;
        this.p_analyzeReports[report_index].end = this.p_analyzeReports[report_index].data.general.chart_data_label.list.length;
      } catch (err) {}
    }
  },
  mounted: function () {},
  destroyed () {},
  methods: {
    f_openCloseChart: function (report_index) {
      this.p_analyzeReports[report_index].show = false;
      this.$forceUpdate();
      setTimeout(function () {
        this.p_analyzeReports[report_index].show = true;
        this.$forceUpdate();
      }.bind(this), 5);
    },
    f_moveChartData: function (report_index = '', direction = '') {
      if (report_index !== '') {
        if (direction === 'right') {
          if (this.p_analyzeReports[report_index].end < this.p_analyzeReports[report_index].data.general.chart_data_label.list.length) {
            this.p_analyzeReports[report_index].start += 1;
            this.p_analyzeReports[report_index].end += 1;
            this.f_openCloseChart(report_index);
          }
        } else if (direction === 'left') {
          if (this.p_analyzeReports[report_index].start !== 0) {
            this.p_analyzeReports[report_index].start -= 1;
            this.p_analyzeReports[report_index].end -= 1;
            this.f_openCloseChart(report_index);
          }
        } else if (direction === 'all') {
          this.p_analyzeReports[report_index].start = 0;
          this.p_analyzeReports[report_index].end = 10000000;
          this.f_openCloseChart(report_index);
        } else if (direction === 'reset') {
          this.p_analyzeReports[report_index].start = this.p_analyzeReports[report_index].data.general.chart_data_label.list.length - this.p_analyzeReports[report_index].max_show_point;
          this.p_analyzeReports[report_index].end = this.p_analyzeReports[report_index].data.general.chart_data_label.list.length;
          this.f_openCloseChart(report_index);
        }

      } else {
        for (let i in this.p_analyzeReports) {
          if (direction === 'right') {
            if (this.p_analyzeReports[i].end < this.p_analyzeReports[i].data.general.chart_data_label.list.length) {
              this.p_analyzeReports[i].start += 1;
              this.p_analyzeReports[i].end += 1;
              this.f_openCloseChart(i);
            }
          } else if (direction === 'left') {
            if (this.p_analyzeReports[i].start !== 0) {
              this.p_analyzeReports[i].start -= 1;
              this.p_analyzeReports[i].end -= 1;
              this.f_openCloseChart(i);
            }
          } else if (direction === 'all') {
            this.p_analyzeReports[i].start = 0;
            this.p_analyzeReports[i].end = 10000000;
            this.f_openCloseChart(i);
          } else if (direction === 'reset') {
            this.p_analyzeReports[i].start = this.p_analyzeReports[i].data.general.chart_data_label.list.length - this.p_analyzeReports[i].max_show_point;
            this.p_analyzeReports[i].end = this.p_analyzeReports[i].data.general.chart_data_label.list.length;
            this.f_openCloseChart(i);
          }
        }
      }
    },
    f_calculateChartWidth: function () {
      let width_val = window.innerWidth - 800;
      return width_val;
    },
    f_calculateChartStyle: function (report) {
      // console.log(window.innerWidth);
      let width_px = (window.innerWidth - 800).toString() + 'px';
      let style = 'margin: 10px;';
      if (report.data.general.chart_type.val.value === 'bar') {
        style += 'height: 200px; width: ' + width_px + ';';
      } else if (report.data.general.chart_type.val.value === 'line') {
        style += 'height: 200px; width: ' + width_px + ';';
      } else if (report.data.general.chart_type.val.value === 'doughnut') {
        style += 'height: 200px; width: 200px;';
      } else if (report.data.general.chart_type.val.value === 'pie') {
        style += 'height: 200px; width: 200px;';
      }
      return style;
    },
    f_getChartData: function (report) {
      let chart_data_label_list = [];
      let dataset_list = [];
      try {
        for (let i in report.data.general.chart_data_label.list) {
          if (parseInt(i) >= report.start && parseInt(i) <= report.end) {
            chart_data_label_list.push(report.data.general.chart_data_label.list[i].chart_data_label_info.val);
          }
        }
      } catch (err) {}
      try {
        for (let i in report.data.general.chart_data_dataset.list) {
          let new_dataset = {
            'data': [],
            'backgroundColor': [],
            'label': report.data.general.chart_data_dataset.list[i].chart_data_dataset_label.val
          };
          if (report.data.general.chart_type.val.value === 'line') {
            new_dataset.lineTension = 0;
            new_dataset.showLine = true;
          }
          try {
            for (let k in report.data.general.chart_data_dataset.list[i].chart_data_dataset_data.list) {
              if (parseInt(k) >= report.start && parseInt(k) <= report.end) {
                let data_value = parseFloat(report.data.general.chart_data_dataset.list[i].chart_data_dataset_data.list[k].chart_data_dataset_data_value.val);
                new_dataset.data.push(data_value);
                let backgroundColor = report.data.general.chart_data_dataset.list[i].chart_data_dataset_data.list[k].chart_data_dataset_data_value_background_color.val;
                new_dataset.backgroundColor.push(backgroundColor);
              }
            }
          } catch (err) {}
          // dataset hazırlandıktan sonra liste içerisine ekleniyor.
          dataset_list.push(new_dataset);
        }
      } catch (err) {}
      /*
        {
          "chart_data": {
            "labels": ['SMS % ', 'Mail % ', 'Push % ', 'Telefon ile arama % ', 'Web Push % '],
            "datasets": [{
              "data": [20, 30, 30, 10, 10],
              "backgroundColor": ['rgba(0,28,73,10)', 'rgba(69,75,10,10)', 'rgba(49,73,91,10)', 'rgba(75,91,84,10)', 'rgba(70,52,75,10)'],
              "label": "Kanal operasyonu yüzdeleri"
            }]
          }
        }
      */
      let chart_data = {
        backgroundColor: "#fff",
        labels: chart_data_label_list,
        datasets: dataset_list
      };
      /*
        datasets: [{
          label: caption,
          lineTension: 0,
          borderColor: '#20a8d8',
          backgroundColor: '#ffffff00',
          showLine: true,
          // backgroundColor: hexToRgba('#20a8d8', 90),
          data: []
        }]
      */
      return chart_data;
    },
  },
  watch: {}
};

</script>

<style type="text/css">
canvas {
  background-color: white;
}

</style>


